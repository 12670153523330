<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        lazy-src="/media/biz-header.jpg"
        src="/media/header2.jpg"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white">
                Invite your Buyers
              </div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <md-card class="bg-success">
                <md-card-content>
                  <h5 class="card-category card-category-social text-white">
                    <i class="far fa-newspaper" /> Invite new Partners to Goose
                  </h5>
                  <h4 class="card-title text-white">
                    Quick Connect
                  </h4>
                  <p class="card-description text-white">
                    Send a message to one of your partners to invite them to
                    Goose. Once enrolled they will automatically be connected
                    with no application needed.
                  </p>

                  <div class="card-stats text-center">
                    <v-btn href="/seller/company-list">
                      Find New Products
                    </v-btn>
                  </div>
                </md-card-content>
              </md-card>
            </div>

            <div class="col-lg-6">
              <div class="card h-100">
                <div class="card-header">
                  <div class="row">
                    <div class="col-8">
                      <h3 class="card-title">Invite Your Partner</h3>
                    </div>
                    <div class="col-4"></div>
                  </div>
                </div>
                <div class=" container card-text">
                  <b-form class="form" @submit.stop.prevent="submit">
                    <div class="form-group row">
                      <div class="col-9">
                        <v-text-field
                          v-model="firstName"
                          :error-messages="firstNameErrors"
                          color="secondary"
                          required
                          label="Name*"
                          prepend-icon="mdi-face"
                          validate-on-blur
                          @input="$v.firstName.$touch()"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-9">
                        <v-text-field
                          v-model="companyName"
                          :error-messages="companyNameErrors"
                          color="secondary"
                          required
                          label="Bussines Name*"
                          prepend-icon="mdi-account"
                          validate-on-blur
                          @input="$v.companyName.$touch()"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-9">
                        <v-text-field
                          v-model="email"
                          :error-messages="emailErrors"
                          color="secondary"
                          label="Seller Email*"
                          prepend-icon="mdi-email"
                          validate-on-blur
                          @input="$v.email.$touch()"
                        />
                      </div>
                    </div>
                    <v-col cols="12" md="6">
                      <v-checkbox
                        v-model="accept"
                        label="Confirm that your business has an existing relationship to the buyer"
                        class="mt-0"
                      />
                    </v-col>
                    <button type="submit" class="btn btn-success mr-2">
                      Send
                    </button>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import ApiService from '@/core/services/api.service'
import { mapGetters, mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      companyName: '',
      firstName: '',
      accept: false,
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    companyName: {
      required,
    },
    firstName: {
      required,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) {
        return errors
      }
      !this.$v.email.required && errors.push('This field is required')
      return errors
    },
    companyNameErrors() {
      const errors = []
      if (!this.$v.companyName.$dirty) {
        return errors
      }
      !this.$v.companyName.required && errors.push('This field is required')
      return errors
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.firstName.$dirty) {
        return errors
      }
      !this.$v.firstName.required && errors.push('This field is required')
      return errors
    },
  },
  methods: {
    ...mapActions([
      'sendEmail',
      'getInviteTotalCount',
      'submitInvite',
      'getUserFromEmail',
      'getCrmTotalCount',
    ]),
    async submit(e) {
      e.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid && this.accept === true) {
        try {
          const referSeller = {
            email: this.email,
            name: this.firstName,
            businessName: this.companyName,
          }
          await ApiService.post('invite', referSeller)
          ;(this.firstName = ''),
            (this.companyName = ''),
            (this.email = ''),
            (this.accept = false)
          Swal.fire({
            title: '',
            text: 'Email sent!',
            icon: 'success',
            confirmButtonClass: 'btn btn-secondary',
          })
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please fill all fields and confirm business relationship!',
        })
      }
    },
  },
}
</script>
